#ShowRoomsHeader {
    padding: 100px 0px;
    @media (max-width: 768px) {
        padding: 50px 0px;
    }
    h1 {
        text-align: center;
        font-family: $mainFont;
        font-size: 50px;
        font-weight: 300;
        padding: 10px 0px;
    }
    p {
        margin: 0 auto;
        font-size: 18px;
        text-align: center;
        font-family: $mainFont;
    }

    .LocationHead {
        .LocationIcons {
            overflow: hidden;

            img {
                margin-top: 10px;
                width: 150px;
                transition: 0.3s ease-in-out;
            }
        }
        &:hover .LocationIcons img {
            transform: scale(1.06);
        }

        .LocationBody {
            h1 {
                font-size: 30px;
                font-family: $mainFont;
                margin: 0;
                color: #3b403f;
                border-bottom: 1px solid #3b403f;
                text-align: start;
            }
            p {
                text-align: start;
            }
        }
    }
    .col-lg-6 {
        padding: 50px 0px;
        @media (max-width: 800px) {
            padding: 5px 0px;
        }
    }
}
