#footer {
    padding: 40px 5px;
    background-color: #4c4846;
    color: white;
    h1 {
        display: inline-block;
        font-size: 25px;
        color: white;
        font-family: $mainFont;
        margin: 0;
        @media (max-width: 576px) {
            text-align: center;
            display: block;
            margin: 10px 0px;
        }
    }
    p {
        display: inline-block;
        margin: 0;
        @media (max-width: 576px) {
            display: block;
            text-align: center;
        }
    }
    .RightFooter {
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    #FooterNavItem {
        list-style: none;
        display: flex;
        padding: 11px 11px;
        li {
            list-style: none;
            margin-left: 15px;
            a {
                text-decoration: none;
                color: white;
                font-size: 18px;
                @media (max-width: 576px) {
                    font-size: 10px;
                }
            }
        }
    }
    #IconsItemFooter {
        list-style: none;
        display: flex;
        li {
            list-style: none;
            margin-left: 20px;
            a {
                color: white;
                text-decoration: none;
                width: 44px;
                height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #a5a3a2;
                background-color: #a5a3a2;
                border-radius: 50%;
                i {
                    font-size: 25px;
                }
            }
            &:hover:nth-child(1) a {
                background: #0a66c2;
                border: none;
            }
            &:hover:nth-child(2) a {
                background: red;
                border: none;
            }
            &:hover:nth-child(3) a {
                background: #c32aa3;
                border: none;
            }
            &:hover:nth-child(4) a {
                background: darkred;
                border: none;
            }
        }
    }
}
