#Immer {
    padding: 30px 0px;
    h1 {
        font-size: 54px;
        font-weight: 200;
        font-family: $mainFont;
        padding: 10px 0px;
    }
    p {
        font-size: $PfontSize;
        margin: 15px 50px;
        padding: 10px 50px;
        line-height: 30px;
        @media (max-width: 768px) {
            margin: 0 auto;
            padding: 10px;
            text-align: center;
        }
    }
    a {
        @include ATagWithButton;
    }
}
