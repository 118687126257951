#UrunDetailSlider {
    padding: 150px 0px;
    $light-grey: #e6e6e6;

    .gallery-slider {
        position: relative;
        overflow: hidden;

        // __images
        &__images {
            margin: 0;
            position: relative;
            .item {
                padding: 0;
                .img-fill {
                    text-align: center;
                    padding: 10px;
                    height: 200px;
                    @media screen and (min-width: 576px) {
                        height: 300px;
                    }
                    @media screen and (min-width: 720px) {
                        height: 450px;
                    }

                    img {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .next-arrow,
            .prev-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 47px;
                height: 47px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.7);
                border: 0 none;
                text-align: center;
                color: #fff;
                z-index: 5;
                opacity: 0;
                transition: all 0.5s;
                outline: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .next-arrow {
                right: 25px;
            }
            .prev-arrow {
                left: 25px;
            }
            &:hover .next-arrow,
            &:hover .prev-arrow {
                opacity: 1;
            }
            .caption {
                width: 100%;
                position: relative;
                text-align: center;
                display: block;
                opacity: 1;
                transition: opacity 0.15s;
                &.hide {
                    opacity: 0;
                }
            }
        }

        // __thumbnails
        &__thumbnails {
            position: relative;
            top: auto;
            left: 0px;
            width: 100%;
            z-index: 4;
            transition: all 0.8s;
            margin: 0;
            padding: 13px 0;
            &:before,
            &:after {
                content: "";
                display: block;
                width: 100px;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 10;
                pointer-events: none;
            }
            &:before {
                left: 0;
                background: linear-gradient(
                    to right,
                    rgba($light-grey, 1) 0%,
                    rgba($light-grey, 0) 100%
                );
            }
            &:after {
                right: 0;
                background: linear-gradient(
                    to right,
                    rgba($light-grey, 0) 0%,
                    rgba($light-grey, 1) 100%
                );
            }
            .item {
                .img-fill {
                    height: 70px;
                    background: white;
                    cursor: pointer;
                    border: 5px solid white;
                    text-align: center;
                    img {
                        opacity: 0.5;
                        transition: all 0.5s;

                        display: inline-block;
                        position: relative;
                        width: 100%;
                        max-height: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        object-fit: cover;
                    }
                }
                &:hover .img-fill img {
                    opacity: 1;
                }
                &.slick-center {
                    img {
                        opacity: 1;
                    }
                }
            }
            .next-arrow,
            .prev-arrow {
                position: absolute;
                width: 40px;
                height: 40px;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                border: 0 none;
                z-index: 15;
                svg {
                    top: 2px;
                }
            }
            .next-arrow {
                right: 0px;
            }
            .prev-arrow {
                left: 0px;
            }
        }

        // generic arrows
        .next-arrow,
        .prev-arrow {
            cursor: pointer;
            svg {
                width: 18px;
                height: 18px;
                position: relative;
            }
        }

        // image fills
        .img-fill {
            width: 100%;
            display: block;
            overflow: hidden;
            position: relative;
            text-align: center;
        }

        // slick overrides
        .slick-slider {
            margin: 0 -7.5px;
            padding: 0 !important;
        }
        .slick-slide {
            float: left;
            padding: 0 7.5px;
            outline: none;
        }
    }
}
#UrunDetail_Body {
    .LeftSideDetail {
        h1 {
            font-size: 29px;
            line-height: 40px;
        }
        p {
            padding: 10px 0px;
            color: #181b20;
            line-height: 22px;
            margin-bottom: 14px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 17px;
        }
    }
    .RightDetailDetail {
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}
#UrunImg {
    padding: 20px 0px;
    h1 {
        font-size: 30px;
        color: #313438;
        font-weight: 400;
    }

    .UrunImgItem {
        cursor: pointer;
        height: 241px;
        transition: 0.2s ease;
        img {
            width: 100%;
            object-fit: cover;
            height: 180px;
        }
        h1 {
            font-size: 20px;
            text-align: center;
            text-transform: capitalize;
            &:hover {
                color: black;
            }
        }
        &:hover {
            box-shadow: 0 12px 24px 8px rgba(162, 162, 162, 0.6);
        }
    }
}
