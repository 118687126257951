#NewSteller {
    padding: 20px 0px;

    .NewStellerbg {
        background-color: $CardBgColor;
        border-radius: 10px;

        .col-lg-3 {
            padding: 0px;
        }

        .NewStellerImg {
            overflow: hidden;

            img {
                height: 204px;
                object-fit: cover;
                width: 100%;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                transform: scale(1);
                transition: 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .NewStellertext {
            padding: 37px 17px;

            a {
                @include ATagWithButton;
            }
        }
    }
}
