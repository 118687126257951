#WorkCard {
    .WordkCardItem {
        border-radius: 10px;
        margin: 10px 0px;
        cursor: pointer;

        .WorkCardImg {
            overflow: hidden;
            img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 100%;
                transform: scale(1);
                transition: 0.3s ease-in-out;
                height: 220px;
                object-fit: cover;

                &:hover {
                    transform: scale(1.095);
                }
            }
        }

        .WorkCardItem {
            background-color: $CardBgColor;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 10px 20px;
            h4 {
                text-align: center;
                color: $textColor;
                font-size: 17px;
            }
            p {
                text-align: center;
                margin: 0 auto;
                text-align: center;
                width: 370px;
                display: contents;
            }
        }
    }
    .col-lg-4 {
        margin-top: 50px;
        @media (max-width: 576px) {
            margin-top: 10px;
            padding-top: 0px;
        }
    }
}
