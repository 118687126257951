#portfolio__detail {
    padding: 100px 0px;
    .protfolio__main__img {
        a {
            text-decoration: none;
        }
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            transition: 0.3s ease;
            transform: scale(1);
            opacity: 0.8;
        }
        .maintext {
            @include Flexible(center, center, none);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.338);
            cursor: pointer;
            h2 {
                font-size: 25px;
                text-transform: uppercase;
                font-weight: 300;
                text-align: center;
                color: white;
            }
        }
        &:hover img {
            transform: scale(1.08);
        }
    }

    .protfolio__main__img_1 {
        a {
            text-decoration: none;
        }
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            transition: 0.3s ease;
            transform: scale(1);
            opacity: 0.8;
        }
        .maintext {
            @include Flexible(center, center, none);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.449);
            cursor: pointer;
            h2 {
                font-size: 35px;
                text-transform: uppercase;
                font-weight: 400;
                text-align: center;
                color: white;
            }
        }
        &:hover img {
            transform: scale(1.08);
        }
    }

    .protfolio__main__img_6 {
        a {
            text-decoration: none;
        }
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 520px;
            object-fit: cover;
            transition: 0.3s ease;
            transform: scale(1);
            opacity: 0.8;
        }
        .maintext {
            @include Flexible(center, center, none);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.449);
            cursor: pointer;
            h2 {
                font-size: 35px;
                text-transform: uppercase;
                font-weight: 400;
                text-align: center;
                color: white;
            }
        }
        &:hover img {
            transform: scale(1.08);
        }
    }
    .protfolio__main__img_12 {
        a {
            text-decoration: none;
        }
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 520px;
            object-fit: cover;
            transition: 0.3s ease;
            transform: scale(1);
            opacity: 0.8;
        }
        .maintext {
            @include Flexible(center, center, none);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.449);
            cursor: pointer;
            h2 {
                font-size: 35px;
                text-transform: uppercase;
                font-weight: 400;
                text-align: center;
                color: white;
            }
        }
        &:hover img {
            transform: scale(1.08);
        }
    }
}
