#portfolio_detail {
    padding: 140px 0px;
    @media (max-width: 768px) {
        padding: 100px 0px;
    }
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-button-next {
            padding: 20px;
            cursor: pointer;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.678);
            color: white;
            font-weight: bold;
            &::after {
                display: none !important;
            }
        }
        .swiper-button-prev {
            cursor: pointer;
            padding: 20px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.678);
            color: white;
            font-weight: bold;

            &::after {
                display: none !important;
            }
        }
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 800px;
        object-fit: cover;
        @media (max-width: 768px) {
            height: 500px;
        }
    }
    .product__info {
        padding: 40px 0px;
        border-bottom: 2px solid gray;
        @media (max-width: 768px) {
            padding: 10px 0px;
        }
        li {
            list-style: none;
            padding: 5px 0px;
        }
    }
    .text-detail {
        h2 {
            padding: 20px 0px;
            font-size: 35px;
            font-weight: 200;
        }
        p {
            font-size: 1rem;
        }
    }
}
