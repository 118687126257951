nav {
    .WrapperW {
        @media (max-width: 991.9px) {
            margin: 0;
        }

        .WrapRow {
            @media (max-width: 991.9px) {
                width: 100%;
            }
        }
    }

    position: fixed;
    top: 40px;
    left: 32px;
    right: 0;
    z-index: 556;
    width: 96%;

    @media (max-width: 991.9px) {
        top: 0px;
        left: 0px;
        right: 0;
        z-index: 556;
        width: 100%;
    }

    .nav {
        width: 100%;
        height: 80px;
        position: sticky;
        top: 0px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 666;

        .logo {
            @media (max-width: 768px) {
                padding-left: 10px;
            }

            float: left;
            width: auto;
            height: auto;
            padding-left: 3rem;

            @media (max-width: 900px) {
                padding-left: 1rem;
            }

            a {
                text-decoration: none;
                color: #797674;
                font-size: 20px;

                img {
                    width: 140px;
                    object-fit: cover;
                }
            }
        }

        .container {
            padding: 0;

            @media (max-width: 992px) {
                display: flex;
            }

            @media (max-width: 768px) {
                display: block;
            }
        }
    }

    .nav div.main_list {
        height: 65px;

        @media (max-width: 800px) {
            margin: 0;
        }
    }

    .nav div.main_list {
        ul {
            width: 100%;
            height: 65px;
            @include Flexible(space-around, center, none);
            list-style: none;
            margin: 0;
            padding: 0;

            .ProductLink {
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                height: 65px;
                padding: 14px 30px;
                color: $mainTextColor;

                @media (max-width: 1260px) {
                    padding: 14px 10px;
                }

                @media (max-width: 1100px) {
                    padding: 14px 10px;
                }

                @media (max-width: 992px) {
                    padding: 14px 0px;
                }

                @media (max-width: 768.9px) {
                    width: 100%;
                }

                i {
                    font-size: 6px;
                    margin-left: 9px;
                    margin-top: 0px;
                }

                .arrowIconRight {
                    font-size: 18px;
                    margin-right: 15px;
                    color: #797674;

                    @media (min-width: 991.9px) {
                        display: none;
                    }
                }

                a {
                    margin-left: 1rem;
                    text-decoration: none;
                    color: #797674;
                    line-height: 65px;
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: uppercase;
                    transition: 0.2s linear;
                    font-family: $mainFont;
                    text-align: start !important;

                    @media (max-width: 768px) {
                        line-height: 38px;
                    }

                    &:hover {
                        color: black;
                    }
                }

                .DropDownClick.active {
                    .dropdownUlWrap {
                        display: block;
                        opacity: 1;
                        top: calc(1%) !important;
                        visibility: visible;
                    }
                }

                &:hover .dropdownUlWrap {
                    opacity: 1;
                    visibility: visible;

                    // @media (max-width: 991.9px) {
                    //     pointer-events: none !important;
                    // }
                }
                .dropdownUlWrap.active {
                    @media (max-width: 991.9px) {
                        display: block !important;
                        top: calc(-3%) !important;
                        height: 100vh;
                        overflow-y: auto;
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .dropdownUlWrap {
                    width: 100%;
                    background-color: #fff;
                    position: absolute;
                    top: calc(100% + 10px);
                    left: 0;
                    z-index: 999;
                    transition: all 0.25s ease-in-out;
                    padding: 25px;
                    visibility: hidden;
                    opacity: 0;

                    .DropdownMenuItemWrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        text-align: start;

                        .dropItemWrap {
                            width: 100%;

                            .dropItemIcon {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                img {
                                    width: 50px;
                                    height: 50px;
                                    object-fit: cover;

                                    @media (max-width: 768px) {
                                        width: 35px;
                                        height: 35px;
                                    }
                                }

                                i {
                                    padding-top: 15px;
                                    font-size: 18px;
                                    color: #797674;
                                }
                            }

                            .DropItemTitle {
                                font-size: 14px;
                                font-weight: normal;
                                color: #1d1d1b;
                                display: flex;
                                align-items: center;

                                span {
                                    border-bottom: 1px solid #1d1d1bdf !important;
                                    display: block;
                                    width: 100%;
                                    text-align: start;
                                    padding: 6px 0px;
                                    white-space: nowrap;
                                }
                            }

                            ul.DropItemWrapper {
                                display: contents;
                                padding-top: 6px;
                                padding-bottom: 10px;
                                // height: auto;
                               
                                @media (max-width: 991.9px) {
                                    display: none;
                                }


                                li.ListItemDrop {
                                    padding: 0 !important;

                                    a {
                                        font-size: 14px !important;
                                        color: #797674;
                                    }

                                    @media (max-width: 768px) {
                                        list-style: disc;
                                        margin-left: 15px;
                                    }
                                }
                            }

                            ul.DropItemWrapper.active {
                                display: block !important;
                                height: auto !important;
                            }

                        }
                    }
                }
            }
        }
    }

    .navTrigger {
        display: none;
    }

    .nav {
        background-color: rgb(255, 255, 255);
        padding-bottom: 10px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .container {
            margin: 0;
        }
    }

    @media screen and (max-width: 991.9px) {
        .navTrigger {
            display: block;
        }

        .nav div.logo {
            padding-left: 10px;
        }

        .nav div.main_list {
            width: 100%;
            height: 0;
            overflow: hidden;
            left: -100%;
            position: relative;
            transition: 0.3s;

            ul {
                flex-direction: column;
                justify-content: start;
                width: 100%;
                height: 100vh;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: white;
                /*same background color of navbar*/
                background-position: center top;

                li {
                    width: 100%;
                    text-align: left;

                    a {
                        text-align: center;
                        width: 100%;
                        font-size: 1rem;
                        color: black;
                    }
                }

                .ProductLink {
                    width: 100%;
                    border-top: 1px solid #797674;
                    // display: block;
                    // &:nth-child(1) {
                    //     margin-top: 30px;
                    // }
                }
            }
        }

        .nav div.show_list {
            height: auto !important;
            top: 35px;
            left: 0;
        }

        .nav div.media_button {
            display: block;
        }
    }

    .navTrigger {
        cursor: pointer;
        width: 30px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
    }

    .navTrigger i {
        background-color: black;
        border-radius: 2px;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
    }

    .navTrigger i:nth-child(1) {
        -webkit-animation: outT 0.8s backwards;
        animation: outT 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
        color: black;
    }

    .navTrigger i:nth-child(2) {
        margin: 5px 0;
        -webkit-animation: outM 0.8s backwards;
        animation: outM 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
        color: black;
    }

    .navTrigger i:nth-child(3) {
        -webkit-animation: outBtm 0.8s backwards;
        animation: outBtm 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse;
        color: black;
    }

    .navTrigger.active i:nth-child(1) {
        -webkit-animation: inT 0.8s forwards;
        animation: inT 0.8s forwards;
        color: black;
    }

    .navTrigger.active i:nth-child(2) {
        -webkit-animation: inM 0.8s forwards;
        animation: inM 0.8s forwards;
        color: black;
    }

    .navTrigger.active i:nth-child(3) {
        -webkit-animation: inBtm 0.8s forwards;
        animation: inBtm 0.8s forwards;
        color: black;
    }

    @-webkit-keyframes inM {
        50% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    @keyframes inM {
        50% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(45deg);
        }
    }

    @-webkit-keyframes outM {
        50% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    @keyframes outM {
        50% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(45deg);
        }
    }

    @-webkit-keyframes inT {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }

        50% {
            -webkit-transform: translateY(9px) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(9px) rotate(135deg);
        }
    }

    @keyframes inT {
        0% {
            transform: translateY(0px) rotate(0deg);
        }

        50% {
            transform: translateY(9px) rotate(0deg);
        }

        100% {
            transform: translateY(9px) rotate(135deg);
        }
    }

    @-webkit-keyframes outT {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }

        50% {
            -webkit-transform: translateY(9px) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(9px) rotate(135deg);
        }
    }

    @keyframes outT {
        0% {
            transform: translateY(0px) rotate(0deg);
        }

        50% {
            transform: translateY(9px) rotate(0deg);
        }

        100% {
            transform: translateY(9px) rotate(135deg);
        }
    }

    @-webkit-keyframes inBtm {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }

        50% {
            -webkit-transform: translateY(-9px) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-9px) rotate(135deg);
        }
    }

    @keyframes inBtm {
        0% {
            transform: translateY(0px) rotate(0deg);
        }

        50% {
            transform: translateY(-9px) rotate(0deg);
        }

        100% {
            transform: translateY(-9px) rotate(135deg);
        }
    }

    @-webkit-keyframes outBtm {
        0% {
            -webkit-transform: translateY(0px) rotate(0deg);
        }

        50% {
            -webkit-transform: translateY(-9px) rotate(0deg);
        }

        100% {
            -webkit-transform: translateY(-9px) rotate(135deg);
        }
    }

    @keyframes outBtm {
        0% {
            transform: translateY(0px) rotate(0deg);
        }

        50% {
            transform: translateY(-9px) rotate(0deg);
        }

        100% {
            transform: translateY(-9px) rotate(135deg);
        }
    }

    .affix {
        padding: 0;
        background-color: #111;
    }

    .myH2 {
        text-align: center;
        font-size: 4rem;
    }

    .myP {
        text-align: justify;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 20px;
    }

    @media all and (max-width: 700px) {
        .myP {
            padding: 2%;
        }
    }
}
#menuIcona {
    cursor: pointer;
}
#IconMenuMobilversions {
    display: none !important;
    @media (max-width: 991.9px) {
        display: block !important;
    }
}
.drop-active {
    display: block !important;
    height: auto !important;
}
.drop-rotate {
    transform: rotate(90deg);
}
