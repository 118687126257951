#HeadSlider {
    img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .your-class {
        position: relative;

        .right {
            @include SliderHeadArrows;
            right: 20px;
        }
        .left {
            @include SliderHeadArrows;
            left: 20px;
        }

        ul {
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0px;
            @include Flexible(center, center, none);

            li {
                list-style: none;
                margin-left: 5px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 2px solid rgb(5, 5, 5);
                @include Flexible(center, center, none);
                cursor: pointer;
            }
        }
        button {
            display: none;
            span {
                background-color: gray;
            }
        }
    }
}
