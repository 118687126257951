#Home-Office {
    padding: 120px 0px;
    @media (max-width: 900px) {
        padding: 10px 0px;
    }
    .LeftHome-Ofice {
        h1 {
            font-size: $mainFont;
            font-size: 34px;
            font-weight: 300;
            padding: 10px 0px;
        }
        p {
            font-size: $PfontSize;
            line-height: 30px;
        }
        a {
            @include ATagWithButton;
        }
    }
    .Righthome-office {
        overflow: hidden;
        img {
            width: 100%;
            animation: PhotoImgSlide 5s linear 2s infinite alternate;
            height: 320px;
        }
    }
    .col-lg-6 {
        margin: 10px 0px;
    }
}
@keyframes PhotoImgSlide {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.055);
    }
    100% {
        transform: scale(1.1);
    }
}
#HomeOffice2 {
    padding: 50px 0px;
    @media (max-wdith: 900px) {
        padding: 10px 0px;
    }
    .HomeOfice2Left {
        h1 {
            font-size: $mainFont;
            font-size: 34px;
            font-weight: 300;
            padding: 10px 0px;
        }
        p {
            font-size: $PfontSize;
            line-height: 30px;
        }
        a {
            @include ATagWithButton;
        }
    }
    .HomeOfice2Right {
        overflow: hidden;
        img {
            width: 100%;
            height: 320px;
            animation: PhotoImgSlide 5s linear 2s infinite alternate;
        }
    }
}
