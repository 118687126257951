#CardDetailImg {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 20px;
}

#CartDetails {
    h1 {
        font-size: 50px;
        padding: 50px 0px;
        font-weight: 300;

        font-family: "Barlow Condensed", sans-serif;
        @media (max-width: 600px) {
            padding: 20px 0px;
        }
    }

    .CardHead_item {
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%;
            transform: scale(1);
            transition: 0.6s ease-in;
            cursor: pointer;
            height: 400px;
            &:hover {
                transform: scale(1.016);
            }
        }
        .cardItem_text {
            padding: 2px 2px;
            h2 {
                font-family: "Barlow Condensed", sans-serif;
                font-size: 32px;
            }
            span {
                font-family: "Barlow Condensed", sans-serif;
                font-size: 20px;
            }
        }
    }
}
