.imgIconLightSTitle:hover {
    background-color: #749fa1;
    color: #fff;
    text-decoration: none;
}
#cetyojDataImg {
    padding-top: 120px;

    .certyojdata {
        position: relative;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .imgIconLight {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 125px;
        left: 140px;
        @include Light;

        @media (max-width: 1560px) {
            top: 21%;
            left: 8%;
        }

        .bg {
            position: absolute;
            background: #749fa100;
            width: 158px;
            height: 115px;
            top: 0%;
            left: -28%;
            transition: 0.3s ease-out;
            @media (max-width: 1560px) {
                top: -98%;
                left: -26%;
            }
        }
    }

    .imgIconLight3 {
        @include Light;
        position: absolute;
        cursor: pointer;
        top: 93px;
        left: 49%;
        width: auto;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #749fa100;
    }

    .imgIconLight5 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: 53.2%;
        top: 28%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 111px;
            height: 87px;
            top: -40px;
            left: -66px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight6 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 55%;
        left: 10%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 153px;
            height: 99px;
            top: -42px;
            left: -76px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight9 {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 37%;
        right: 12%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 140px;
            height: 91px;
            top: -41px;
            left: -64px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight10 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 39%;
        left: 9.4%;
        @include Light;
        display: flex;
        align-items: center;
        justify-content: center;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 139px;
            height: 72px;
            top: -26px;
            left: -67px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight11 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 25%;
        left: 31%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: -51px;
            left: -34px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight15 {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 22%;
        right: 30%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 116px;
            height: 87px;
            top: -44px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight16 {
        position: absolute;
        cursor: pointer;
        top: 19%;
        left: 43%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: -40px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    // <!-- SECTION 2 FERDI ISH PLANI -->

    .imgIconLight22 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 19%;
        left: 28%;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            left: -23%;
            bottom: -74%;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight23 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 26%;
        left: 28%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: -40px;
            left: 130px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight24 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 39%;
        left: 31%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 177px;
            height: 100px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight25 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 46%;
        left: 47%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 103px;
            top: -40px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight26 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 29%;
        left: 67%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 168px;
            height: 115px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight27 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 23%;
        left: 57%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 102px;
            height: 156px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight28 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 14%;
        left: 76%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLight29 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 14%;
        left: 85%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 131px;
            height: 87px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    // SECTION 3
    .imgIconLight30 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: 10%;
        bottom: 20%;
        flex-direction: row-reverse;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 144px;
            height: 87px;
            top: -22px;
            left: 70px;
            transition: 0.3s ease-out;
        }
    }

    // SECTION 4

    .imgIconLightS40 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: 5%;
        bottom: 34%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 144px;
            height: 87px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS41 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        left: 24%;
        bottom: 19%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: -40px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS42 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 126px;
        left: 20%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: -19px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS43 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 45%;
        flex-direction: row;
        right: 10%;
        @include Light;

        .bgs2 {
            position: absolute;
            background: rgba(116, 159, 161, 0);
            background-color: rgba(116, 159, 161, 0);
            background-color: rgba(116, 159, 161, 0);
            width: 88px;
            height: 138px;
            top: -2px;
            left: -54px;
            transition: 0.3s ease-out;
        }
    }

    // SECTION 5

    .imgIconLightS50 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 19%;
        right: 15%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 105px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS51 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 69%;
        right: 17%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 178px;
            height: 112px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS52 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 32%;
        right: 38%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 260px;
            height: 120px;
            top: 0;
            left: 30px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS53 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 53%;
        left: 4%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 157px;
            height: 103px;
            top: 0;
            left: 7px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS54 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 66%;
        left: 9%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 90px;
            height: 95px;
            top: 0;
            left: 7px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS55 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 79%;
        left: 49%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 128px;
            height: 112px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS56 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 60%;
        right: 27%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 87px;
            top: 45px;
            left: 15px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS57 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 78%;
        left: 10%;
        flex-direction: row;
        @include Light;

        .bgs2 {
            position: absolute;
            background: #749fa100;
            width: 190px;
            height: 150px;
            top: -60px;
            left: -100px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS58 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        bottom: 35%;
        left: 28%;
        @include Light;

        .bg {
            position: absolute;
            background: #749fa100;
            width: 112px;
            height: 90px;
            top: 0;
            left: 0;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS60 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 59%;
        left: 66%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 105px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS61 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 43%;
        left: 28%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 105px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS62 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 41%;
        left: 18%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: #749fa100;
            width: 100px;
            height: 105px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS63 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 49%;
        left: 61%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: #749fa100;
            width: 210px;
            height: 65px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS64 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 74%;
        left: 67%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: #749fa100;
            width: 250px;
            height: 100px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS65 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 80%;
        left: 94%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: rgba(116, 159, 161, 0);
            background-color: rgba(116, 159, 161, 0);
            width: 60px;
            height: 50px;
            top: -40px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    .imgIconLightS66 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;
        top: 52%;
        left: 94%;
        flex-direction: row;
        @include Light;

        .bgs3 {
            position: absolute;
            background: rgba(116, 159, 161, 0);
            background-color: rgba(116, 159, 161, 0);
            width: 60px;
            height: 60px;
            top: -35px;
            left: -55px;
            transition: 0.3s ease-out;
        }
    }

    // ********************************************

    @keyframes icon-pulse {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1.2);
            opacity: 0;
        }
    }

    @media (max-width: 1100px) {
        top: -39px;
        left: -59px;
    }

    @media (max-width: 999.9px) {
        display: none;
    }
}

#CertyojDetails {
    padding: 100px 0px;

    @media (max-width: 768px) {
        margin-top: 70px;
    }

    .certyojRoomsData {
        display: none;
    }

    .detailactive {
        display: block;
    }

    .ImgOda {
        img {
            height: 450px;
            width: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: 600px) {
        padding: 50px 0px;
    }

    h1 {
        font-family: "Barlow Condensed", sans-serif;
        font-size: 55px;
        font-weight: 300;

        @media (max-width: 600px) {
            font-size: 45px;
            display: inline-block;
        }
    }

    p {
        text-align: start;
        padding: 15px 3px;
        width: 742px;
        display: inline-block;
        font-size: 17px;

        @media (max-width: 850px) {
            width: auto;
        }
    }

    a {
        color: #000;
        text-decoration: none;
    }

    .Certyoj_item {
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 70px;

        @media (max-width: 800px) {
            margin-bottom: 10px;
            margin-left: 0;
        }

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            transition: 0.4s ease-in;
            cursor: pointer;

            &:hover {
                transform: scale(1.023);
            }
        }

        .certyojItem_text {
            padding: 10px 5px;
            background-color: #e8e1d8;

            h6 {
                text-align: center;
                font-size: 17px;
                font-weight: 400;
            }
        }
    }
}

.imgIconLightSTitle {
    padding: 5px 10px;
    border: 2px solid #749fa1;
    border-radius: 25px;
    font-size: 14px;
    color: #749fa1;
    background-color: white;
    font-weight: bold;
    margin: 0px 0px 0px 25px;
    transition: 0.3s all;
    z-index: 10;
}

.bgHasClass {
    .bg,
    .bgs2,
    .bgs3 {
        background-color: #749fa14d !important;
    }

    .imgIconLightSTitle {
        color: #ffffff;
        background-color: #749fa1;
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev::after {
    display: none;
}

.swiper-button-next {
    @include Border;
}

.swiper-button-prev {
    @include Border;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 450px;
    object-fit: cover;

    @media (max-width: 768px) {
        height: 100%;
    }
}

.swip-item {
    display: flex;
    justify-content: space-between;

    border: 1px solid #dee2e6;

    @media (max-width: 992px) {
        flex-direction: column;
    }

    .swipImg {
        width: 65%;

        @media (max-width: 992px) {
            width: auto;
        }
    }

    .swip-text {
        width: 35%;

        @media (max-width: 992px) {
            width: auto;
        }

        p {
            font-size: 16px !important;
            text-align: start !important;
            padding: 11px !important;
            width: 100% !important;
        }

        h2 {
            padding: 10px;
            font-size: 24px;
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    top: 90%;
}
