#Büromöbel {
    font-family: $mainFont;
    padding: 30px 0px;
    h3 {
        text-align: center;
        font-size: 54px;
        font-family: $mainFont;
        font-weight: 200;
        padding: 20px 0px;
    }
    p {
        text-align: center;
        margin: 2px 90px;
        padding: 2px 91px;

        font-size: $PfontSize;
        line-height: 30px;
        @media (max-width: 768px) {
            margin: 0px auto;
            padding: 0;
        }
    }
}
