@mixin SliderHeadArrows {
    position: absolute;
    top: 50%;
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        border-color: bisque;
    }

    z-index: 555;
}
@mixin Flexible($justify, $items, $direction) {
    display: flex;
    justify-content: $justify;
    align-items: $items;
    flex-direction: $direction;
}
@mixin ATagWithButton {
    text-decoration: none;
    color: #a5a3a2;
    border: 1px solid #797674;
    margin-top: 30px;
    border-radius: 20px;
    padding: 5px 15px;
    text-transform: uppercase;
    transition: 0.2s all;
    &:hover {
        background-color: #797674;
        border-color: #797674;
        color: #fff;
    }
}

@mixin MainButton {
    border-radius: 20px;
    color: #a5a3a2;
    border: 1px solid #797674;
    padding: 5px 10px;
    transition: 0.2s ease;
    &:hover {
        background-color: #797674;
        color: white;
        border: none;
    }
}

@mixin Light {
    .icon-pulse {
        position: relative;
        width: 15px;
        height: 15px;
        background: #084655;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        color: #fff;

        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            border: 1px solid #0b3353;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            animation: icon-pulse 1.5s linear infinite;
            opacity: 0;
        }

        &:after {
            animation-delay: 0.5s;
        }
    }
}
@mixin Border {
    width: 40px;
    height: 40px;
    border: 1px solid gray;
    border-radius: 50%;
    button {
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
