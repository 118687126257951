#IconFoot {
    padding-top: 100px;
    @media (max-width: 900px) {
        padding-top: 100px;
    }
    @media (max-width: 600px) {
        padding-top: 50px;
    }
    padding-bottom: 5px;

    @media (max-width: 768px) {
        padding: 50px 0px;
    }
    @media (max-width: 576px) {
        padding: 20px 0px;
    }

    a {
        text-decoration: none;
        color: black;
    }
    .LeftKontact {
        padding: 35px 89px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        background-color: #a5a3a2;
        position: relative;
        @media (max-width: 768px) {
            padding: 21px 4px;
        }
        img {
            width: 100px;
        }
        .bgLogo {
            top: 0px;
            right: -57px;
            position: absolute;
            opacity: 0.5;
            img {
                width: 240px;
                opacity: 0.7;
            }
            @media (max-width: 576px) {
                width: 170px;
            }
        }
    }
    .RightContact {
        position: relative;
        padding: 35px 89px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: #a5a3a2;
        @media (max-width: 768px) {
            padding: 21px 4px;
        }
        i {
            font-size: 50px;
            color: white;
        }
        .bgLogo {
            top: -22px;
            right: 20px;
            position: absolute;

            i {
                font-size: 163px;
                opacity: 0.7;
                @media (max-width: 576px) {
                    font-size: 140px;
                }
            }
            @media (max-width: 768px) {
                top: -26px;
                right: -16px;
            }
            @media (max-width: 576px) {
                top: -2px;
                right: -16px;
            }
        }
    }
    .ContacText {
        margin-left: 20px;
        h1 {
            font-family: $mainFont;
            color: white;
        }
        p {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 1px;
            color: white;
        }
    }
}
