#ContactLocations {
    padding: 30px 0px;

    .LocationData {
        h1 {
            font-family: $mainFont;
            font-size: 37px;
            font-weight: 300;
            text-align: center;
        }
        text-align: center;
        ul {
            li {
                padding: 5px 0px;
                list-style: none;
                a {
                    text-decoration: none;
                    color: black;
                }
                #mail {
                    text-decoration: underline;
                }
            }
        }
    }
}
#ContactItemImg {
    height: 60vh;
    @media (max-width: 768px) {
        height: auto;
    }
    .ItemHead {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .itemImg {
            img {
                width: 180px;
            }
        }
        .Itemtext {
            h1 {
                font-size: 30px;
                font-family: $mainFont;
                font-weight: 300;
                border-bottom: 1px solid #797674;
                padding: 10px 0px;
            }

            p {
                font-size: 17px;
                font-family: $mainFont;
                letter-spacing: 1px;
            }
            button {
                @include MainButton;
            }
            @media (max-width: 768px) {
                text-align: center;
            }
        }
    }
}

#ContactForm {
    padding: 60px 0px;
    h1 {
        font-size: 36px;
        font-weight: 300;
        font-family: $mainFont;
    }
    p {
        padding: 10px 0px;
    }

    form {
        textarea {
            width: 100%;
            border: 1px solid #a5a3a2;
            border-radius: 5px;
            &::placeholder {
                padding: 10px 10px;
                font-family: $mainFont;
                color: #a5a3a2;
                transition: 0.3s ease-in-out;
            }
            &:focus::placeholder {
                padding: 0;
                font-size: 10px;
            }
        }
        select {
            border: 1px solid #a5a3a2;
            border-radius: 5px;
            margin: 10px 0px;
            padding: 8px 10px;
            width: 100%;
            color: #a5a3a2;
            font-family: $mainFont;
            option {
                width: 100%;
                color: #a5a3a2;
                font-family: $mainFont;
            }
        }
        input {
            margin: 10px 0px;
            width: 100%;
            padding: 8px 10px;
            color: #a5a3a2;
            border: 1px solid #a5a3a2;
            border-radius: 5px;
            transition: 0.2s ease-in-out;
            font-family: $mainFont;
            &::placeholder {
                padding: 10px 10px;
                font-family: $mainFont;
                color: #a5a3a2;
                transition: 0.3s ease-in-out;
            }
            &:focus::placeholder {
                padding: 0;
                font-size: 10px;
            }
        }
    }
    #Button_ContactText {
        font-family: $mainFont;
        color: #0f0f0f;
        font-size: 17px;
    }
    button {
        background-color: #749fa1;
        color: white;
        width: 130px;
        border: 1px solid black;
        transition: 0.2s ease-in-out;
        padding: 5px 0px;
        border: none;
        border-radius: 20px;
        &:hover {
            background-color: #797674;
            color: white;
            border: none;
        }
    }
}
