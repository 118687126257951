$BebasFont: "Bebas Neue", cursive;
$Lexendfont: "Lexend Tera", sans-serif;
$Opensfont: "Open Sans", sans-serif;
$Poppinsfont: "Poppins", sans-serif;

$mainFont: "Barlow Condensed", sans-serif;
$PfontSize: 1rem;
$CardBgColor: #f1f1f1;
$textColor: #4c4846;
$grayColor: #c4c4c4;
$DarkBlue: #1794db;
$LightBlue: #d8ecff;
$VeryligthBlue: #edf6ff;
$RedColor: #ee1d24;
$WhiteColor: #ffffff;
$textFont: 1rem;
$footerBg: #203569;
$mainTextColor: #203569;
$h1Font: 40px;
