#ProductManin {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#ProductHead {
    padding: 30px 0px;

    h1 {
        font-family: $mainFont;
        padding: 10px 0px;
        font-size: 56px;
        font-weight: lighter;
    }

    p {
        line-height: 31px;
        padding: 0px 199px;
        font-size: 17px;
        font-weight: 400;
        text-align: center;

        @media (max-width: 768px) {
            padding: 0px 30px;
        }
    }
}

#Product {
    padding-bottom: 200px;

    @media (max-width: 900px) {
        padding-bottom: 20px;
    }

    .ProductHead {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover .ProductImg img {
            transform: scale(1.06);
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    .ProductImg {
        padding: 10px 0px;
        overflow: hidden;
        width: 100%;

        @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 180px;
            transition: 0.4s ease;
        }
    }

    .ProductBody {
        h2 {
            font-size: 1.8rem;
            color: #3b403f;
            border-bottom: 1px solid #3b403f;
            padding-bottom: 3px;
            margin-bottom: 4px;
            font-family: $mainFont;
            font-weight: 200;
        }

        button {
            @include MainButton;
        }
    }
}
