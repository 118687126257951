#WarmSedus {
    padding: 40px 0px;
    h1 {
        font-family: $mainFont;
        padding: 30px 0px;
    }
    .WarmSedusHome {
        .SeducIcons {
            text-align: center;
            img {
                width: 90px;
                height: 200px;
                object-fit: contain;
            }
        }
        .SedusText {
            text-align: center;
            h1 {
                font-size: 34px;
                font-weight: 300;
                a {
                    text-decoration: none;
                    color: black;
                }
            }
            p {
                padding: 0px 50px;
                font-size: $PfontSize;
            }
        }
    }
}
