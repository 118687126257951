#BlogItemSection {
    padding: 20px 0px;

    .single_post {
        h2 {
            text-align: center;
        }
        .list_none {
            display: flex;
            justify-content: space-between;
            li {
                a {
                    text-decoration: none;
                    color: gray;
                }
            }
        }
        .blog_img {
            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .single_img {
            position: relative;
            height: 376px;
            img {
                max-width: 100%;
            }
        }
    }

    .post_footer {
        list-style: none;
        .post_img {
            position: relative;
            display: inline-block;
            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
            }
        }
        .post_content {
            h6 {
                a {
                    text-decoration: none;
                    color: gray;
                }
            }
        }
    }
    input {
        &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid gray;
        }
    }
    .box_shadow1 {
        .blog_img {
            overflow: hidden;
            position: relative;
            margin: 10px 0px;
            height: 350px;
            @media (max-width: 768px) {
                height: auto;
            }
            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }
}
.widget {
    .widget_recent_post {
        list-style: none;
    }
}
