#UrunlerHead {
    padding: 100px 0px;

    @media (max-width: 768px) {
        padding: 10px 0px;
    }

    a {
        text-decoration: none;
    }

    .UrunBody {
        .UrunImg {
            overflow: hidden;
            img {
                width: 100%;
                transition: 0.2s ease;
                height: 300px;
                object-fit: contain;
                &:hover {
                    transform: scale(1.095);
                }
            }
        }
    }
    .UrunDetails {
        h5 {
            font-size: 16px;
            font-weight: 500;
            color: #797674;
            padding: 10px 0px;
            text-transform: uppercase;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
                color: black;
            }
        }
        h1 {
            font-size: 16px;
            font-weight: 400;
            color: #797674;
            cursor: pointer;
            transition: 0.2s ease;
            padding: 10px 0px;
            border-bottom: 1px solid gray;
            &:hover {
                color: black;
            }
        }
        p {
            font-size: 15px;
            font-weight: 400;
            color: #797674;
        }
    }
}
