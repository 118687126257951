// Fonts
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lexend+Tera:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

// Sweet Alert2
@import "~sweetalert2/src/sweetalert2";

// Swiper Slider
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";
@import "~swiper/css/autoplay";

// Slick Carousel
@import "~slick-carousel-latest/slick/slick";

// AOS
@import '~aos/dist/aos.css';

// Custom styles
@import "front/variables";
@import "front/mixin";
@import "front/pages/home/slider-head";
@import "front/pages/home/buro-mebel";
@import "front/pages/home/work-card";
@import "front/pages/home/immer";
@import "front/pages/home/home-office";
@import "front/pages/home/warm-sedus";
@import "front/pages/home/new-steller";
@import "front/pages/home/icon-foot";
@import "front/pages/partials/footer";
@import "front/pages/products/main-product";
@import "front/pages/contact/contact";
@import "front/pages/partials/navbar";
@import "front/pages/products/products";
@import "front/pages/products/product-detail";
@import "front/pages/home/showroom";
@import "front/pages/blog/blog";
@import "front/pages/blog/blog-detail";
@import "front/pages/card-detail/detail";
@import "front/pages/sketch/sketch";
@import "front/pages/portfolio/portfolio";
@import "front/pages/portfolio/portfolio-detail";

*,
*::after,
*::before {
    box-sizing: border-box;
    offset: none;
    margin: 0;
    padding: 0;
}
