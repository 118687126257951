#BlogPageImg {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#BlogHead {
    padding: 50px 0px;
    .col-lg-4 {
        a {
            text-decoration: none;
            color: black;
        }
    }

    .Head_blog {
        .BlogPageImg {
            overflow: hidden;
            img {
                width: 100%;
                height: 381px;
                object-fit: cover;
                transition: 0.3s ease-in;
                &:hover {
                    transform: scale(1.087);
                }
            }
        }
        .BlogText {
            p {
                padding: 5px 17px;
                font-size: 20px;
                font-weight: 500;
                color: gray;
                margin: 0;
            }
            #BlogDate {
                padding: 10px 18px;
                font-size: 15px;
                font-weight: 500;
                border-bottom: 1px solid gray;
            }
            h6 {
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
            }
        }
        .BlogPageReadMore {
            a {
                text-decoration: none;
                color: black;
                font-size: 14px;
                letter-spacing: 1.3px;
                font-weight: 500;
                transition: 0.3s ease-in;
                i {
                    color: red;
                }
                &:hover {
                    color: red;
                }
            }
        }
    }
}
